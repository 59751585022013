import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "resend-email",
    path: `${AUTH_PREFIX_PATH}/success-send-email`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/resend-email/forgot")
    ),
  },
  {
    key: "reset-password",
    path: `${AUTH_PREFIX_PATH}/reset-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/reset-password")
    ),
  },
  {
    key: "change-password",
    path: `${AUTH_PREFIX_PATH}/change-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/change-password")
    ),
  },
  {
    key: "error",
    path: `*`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-1")),
    meta: {
      blankLayout: true,
    },
  },
];
export const publicNoRedirect = [
  {
    key: "invoice",
    path: `/invoice`,
    component: React.lazy(() => import("views/app-views/invoice")),
    meta: {
      blankLayout: true,
    },
  },
  {
    key: "bstb",
    path: `/bstb`,
    component: React.lazy(() => import("views/app-views/bstb")),
    meta: {
      blankLayout: true,
    },
  },
];

export const protectedRoutes = [
  {
    key: "dashboard",
    path: `${APP_PREFIX_PATH}`,
    component: React.lazy(() => import("views/app-views/onboarding")),
  },
  {
    key: "booking-order",
    path: `${APP_PREFIX_PATH}/booking-order`,
    component: React.lazy(() => import("views/app-views/booking-order")),
  },
  {
    key: "voyage",
    path: `${APP_PREFIX_PATH}/voyage`,
    component: React.lazy(() => import("views/app-views/voyage")),
  },
  {
    key: "history",
    path: `${APP_PREFIX_PATH}/history`,
    component: React.lazy(() => import("views/app-views/history")),
  },
  {
    key: "soa",
    path: `${APP_PREFIX_PATH}/soa`,
    component: React.lazy(() => import("views/app-views/statement-of-account")),
  },
  {
    key: "soa",
    path: `${APP_PREFIX_PATH}/soa/detail`,
    component: React.lazy(() =>
      import("views/app-views/statement-of-account/detail")
    ),
  },
  // Profile
  {
    key: "my-profile",
    path: `${APP_PREFIX_PATH}/profile`,
    component: React.lazy(() => import("views/app-views/profile")),
  },
  {
    key: "error",
    path: `*`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-1")),
    meta: {
      blankLayout: true,
    },
  },
];
