import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  DIR_LTR,
} from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";
import { AUTH_PROFILE } from "constants/AuthConstant";

export const APP_NAME = "Kimulti Customer Channel";
export const APP_VERSION = "6.0";
export const API_BASE_URL =
  env.REACT_APP_BASE_URL || "https://api.kitrans.my.id";
export const API_CDN_URL =
  env.REACT_APP_CDN_URL || "https://cdn.kitrans.my.id/uploads";
export const CHANGELOG_URL =
  env.REACT_APP_CHANGELOG_URL || "https://changelog.kitransnet.com/";
export const APP_PREFIX_PATH = "/dashboard";
export const AUTH_PREFIX_PATH = "/auth";
export const REDIRECT_URL_KEY = "redirect";
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}`;
export const UNAUTHENTICATED_ENTRY = "/login";

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "en",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme:
    localStorage.getItem("kimulti_theme") === "dark" ? "dark" : "light",
  direction: DIR_LTR,
  blankLayout: false,
};

export const USER_SESSION_CONFIG = {
  storage: localStorage.getItem(AUTH_PROFILE),
};
